@font-face {
	font-family: 'Avenir';
	src: url('../Avenir/Avenir-Black.ttf') format('truetype');
	font-weight: 900; /* Black */
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../Avenir/Avenir-Book.ttf') format('truetype');
	font-weight: 400; /* Book/Regular */
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../Avenir/Avenir-Heavy.ttf') format('truetype');
	font-weight: 700; /* Heavy/Bold */
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../Avenir/Avenir-Light.ttf') format('truetype');
	font-weight: 300; /* Light */
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../Avenir/Avenir-Regular.ttf') format('truetype');
	font-weight: 500; /* Regular/Medium */
	font-style: normal;
}

.refresh-button {
  flex: 0 0 auto;
  padding: 0 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for visual feedback */
}
/* Header containing buttons and generated title */
.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between buttons and title */
  padding: 10px;
  background-color: #f5f5f5; /* Light background for header */
  border-bottom: 1px solid #ddd; /* Separator line */
  flex-shrink: 0; /* Prevent shrinking */
}


.refresh-button img {
  width: 24px; /* Adjust as needed */
  height: auto;
  transition: transform 0.3s ease; /* Smooth transition for scaling */
}

/* Hover effect for the button */
.refresh-button:hover {
  background-color: #f2f2f2; /* Light background on hover */
  transform: scale(1.05); /* Slightly increase size */
}

/* Hover effect specifically for the image */
.refresh-button:hover img {
  transform: rotate(90deg); /* Rotate the icon for a dynamic effect */
}



.dropdown-wrapper {
	position: relative;
}

.dropdown-icon {
	cursor: pointer;
	background-image: url('dropdown-icon.svg');
	background-size: contain;
	background-repeat: no-repeat;
	width: 25px;
	height: 25px;
	display: inline-block;
	transition: box-shadow 0.1s ease-in-out; /* Added transition for smooth effect */
}


.dropdown-icon:hover {
	box-shadow: 0 0 8px rgba(211, 148, 247, 0.913); /* Glow effect */
}

.dropdown-menu {
	display: none;
	position: absolute;
	right: 4px;
	top:calc(100% - 5px);
	background-color: #fff;
	border: 1px solid #ccc;
	z-index: 1;
}

.dropdown-wrapper:hover .dropdown-menu {
	display: block; /* Shows the dropdown when hovering over the wrapper */
}



/* Distinct styles for the new WorkHub link */
.workhub-wrapper {
	display: flex;
	align-items: center;
	gap: 5px; /* Space between icon and link */
	border: 2px solid #8118ab; /* Green border to make it stand out */
	padding: 5px;
	border-radius: 5px;
}

.workhub-icon {
	width: 20px;
	height: 20px;
	background-color: #7b85fc;
	background-image: url('workhub-icon.png');
	background-size: cover;
	background-position: center;
	border-radius: 50%; /* Make it circular */
	display: inline-block;
}

.nav-link-special a {
	color: #8118ab; /* Green text to match the icon */
	text-decoration: none;
	font-weight: bold;
	transition: color 0.3s;
}

.nav-link-special a:hover {
	color: #c832cb; /* Darken the green on hover */
}


.dashboard-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
	padding: 20px 0;
	background-color: #fff;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
	flex-wrap: wrap;
}



/* Media Query for smaller screens */
@media (max-width: 768px) {
	.dashboard-header {
		flex-direction: column; /* Stack the header items vertically */
		text-align: center; /* Center-align text */
		align-items: center; /* Center-align flex items */
	}

	.left-header {
			flex-wrap: wrap; /* Allow items to wrap if they don't fit */
			justify-content: center; /* Center items horizontally */
		}
	
	.logo {
		width: 35px;  /* Smaller width */
		height: 35px;  /* Smaller height */
		margin-right: 8px; /* Smaller margin to the right */
		margin-left: 10px;
	}

	.noktHeader {
		font-size: 16px;  /* Further reduce the font size */
		margin: 0; /* Eliminate any potential margin */
	}

	.nav-links {
		width: 100%; /* Take the full width of the parent container */
		justify-content: center; /* Center the navigation links horizontally */
		margin-top: 10px; /* Space out the navigation links from the title */
	}

}


.left-header {
	display: flex;
	align-items: center;
}

.logo {
	width: 50px;
	height: 50px;
	margin-right: 10px;
	margin-left: 20px;
	
}

.noktHeader {
	font-size: 28px;
	margin: 0;
	color: #2e2f32;
	flex: 0 0 auto;
}

.nav-links {
	display: flex;
	align-items: center;
	gap: 20px;
	margin-right: 40px;
	font-size: 16px;
	z-index: 0; /* Make sure dropdown appears on top */
}

.nav-link {
	cursor: pointer;
	transition: color 0.3s;
}

.nav-link:hover {
	color: #a2a3e9;
}

.filters-container {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 1px 0px 5px rgba(0,0,0,0.05);
	background-color: #e8e9ed;
	margin-right: 10px; 
	margin-left: 10px;
}

.filter-title {
	margin-left: 3px;
	margin-bottom: 5px;
	font-weight: 600;
	text-align: left;
	color: #2e2f32;
}

.filter {
	flex: 1;
	display: flex;
	flex-direction: column;
	margin: 0 10px;
}

.filter select, .filter input[type="text"] {
	width: 95%;
	padding: 10px;
	font-size: 14px;
	border: 1px solid #a2a3e9;
	border-radius: 4px;
	background-color: #fff;
	box-shadow: none; /* Optional: if you want to remove any default browser shadow */
}

/* Additional styles for focusing on input and select */
.filter input[type="text"]:focus, .filter select:focus {
	outline: none;
	border-color: #007bff; /* Adjust this color as needed */
}

/* You might also want to add some styles for the React Select component if you're using it */
.Select__control {
	min-height: 38px; /* Match the height of your input and select fields */
	border-color: #a2a3e9; /* Match the border color */
}

/* If you have any additional customizations for React Select */
.Select__single-value, .Select__placeholder {
	font-size: 14px; /* Match the font size of your inputs */
}

.chat-list {
  list-style-type: none;
  padding: 0;
  background-color: #fff;
  border-radius: 5px;
  margin: 0 20px; /* Horizontal margins */
}

.chat-list-header,
.chat-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px; /* Vertical padding */
}


/* Style for the index column */
.chat-list-header > div:first-child,
.chat-item > div:first-child {
  padding: 0 10px; /* Horizontal padding */
  text-align: left;
  flex: 0 0 8%; /* Flex grow, flex shrink, flex basis */
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Style for the remaining columns */
.chat-list-header > div:not(:first-child),
.chat-item > div:not(:first-child) {
  padding: 0 10px; /* Horizontal padding */
  text-align: left;
  flex: 0 0 23%; /* Flex grow, flex shrink, flex basis */
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.chat-list-header {
  background-color: #e8e9ed;
  font-weight: 600;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-left:20px;
  margin-right: 20px;
}

.chat-item {
  border-bottom: 1px solid #e1e1e1;
}

.chat-item:hover {
  background-color: #e6e6e6;
}





.sort-indicator {
	margin-left: 5px;
}

.asc::after {
	content: "↑";
}

.desc::after {
	content: "↓";
}


.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-content {
	position: relative; 
	background: #e5ddd5; /* Background color similar to WhatsApp light theme */
	padding: 30px;
	padding-top:50px;
	max-width: 40vw;
	max-height: 80vh;
	overflow-y: auto;
	font-family: 'Avenir';
	border-radius: 15px;
}

.chat-container {
	max-height: 1200px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	min-width: 400px; /* Example minimum width */
	min-height: 300px; /* Example minimum height */
}


.chat-message {
	padding: 10px 15px;
	margin: 10px 0;
	border-radius: 15px;
	max-width: 70%;
	word-wrap: break-word;
	align-self: flex-start; /* Default alignment for incoming messages */
	
	
}

.chat-message.left {
	align-self: flex-start;
	background-color: #f0f0f0;
	color: #2e2f32;

}


.chat-message.right {
	align-self: flex-end;
	background-color: #dcf8c6;
	color: #2e2f32;

}

.chat-user {
	font-weight: bold;
	margin-bottom: 5px;
}

.chat-body {
	margin-top: 5px;
}

.chat-timestamp {
	font-size: 0.8em;
	color: gray;
	text-align: right;
	margin-top: 5px;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #333; /* You can adjust this color if needed */
}
	
.close-button:hover {
	background-color: #a2a3e9;
}

/* Styling for the button container */
.button-container {
	font-family: 'Avenir';
	display: flex; 
	justify-content: flex-end; /* Align the buttons to the right */
	gap: 10px; /* Minimal space between the buttons */
 /* Space from the chat container or any other element above */
}



/* Styling for the copy room id button */
.copy-roomid-button {
	color: #fff; /* Blueish-purple to blend in */
}

.copy-roomid-button:hover {
	background-color: rgba(255, 255, 255, 0.65);
	color: purple; /* A very light blueish-purple background on hover */
}

/* Styling for the see full history button */
.see-full-history-button {
	color: #fff; /* Blueish-purple to blend in */
}

.see-full-history-button:hover {
	background-color: rgba(255, 255, 255, 0.65);
	color: purple;
}

.copy-roomid-button.greened-out {
	color: #fff; 
	background-color: #b2e6c5; /* A shade of green */
	pointer-events: none; /* Disable any further interactions */
}


.analyze-chat-history-button {
	position: absolute; /* Position the button absolutely within its relative parent */
	top: 10px; /* Adjust as needed to position from the top */
	left: 10px; /* Adjust as needed to position from the left */
	z-index: 10; /* Ensure it's above other content if needed */
	transition: background-color 0.3s, color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
	width: 90px; /* Example width, adjust as needed */
	height: 30px; /* Example height, adjust as needed */
	padding: 4px 8px; /* Reduced padding */
	font-size: 12px; /* Reduced font size */
	text-align: center; /* Center the text inside the button */
	line-height: 22px; /* Adjust line height to vertically center text, depends on button height */
	overflow: hidden; /* Prevents content from spilling outside */
	white-space: nowrap; /* Keeps the text on one line */
	border-radius: 15px;

}

.analyze-chat-history-button:hover {
	background-color: #5f51a0; /* Example hover background color (blue) */
	color: #ffffff; /* Example text color on hover (white) */
	box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Example shadow for depth effect */
	cursor: pointer; /* Change cursor to indicate it's clickable */
}


.export-chat-history-button {
	position: absolute; /* Position the button absolutely within its relative parent */
	top: 10px; /* Adjust as needed to position from the top */
	left: 110px; /* Adjust as needed to position from the left */
	z-index: 10; /* Ensure it's above other content if needed */
	transition: background-color 0.3s, color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
	width: 90px; /* Example width, adjust as needed */
	height: 30px; /* Example height, adjust as needed */
	padding: 4px 8px; /* Reduced padding */
	font-size: 12px; /* Reduced font size */
	text-align: center; /* Center the text inside the button */
	line-height: 22px; /* Adjust line height to vertically center text, depends on button height */
	overflow: hidden; /* Prevents content from spilling outside */
	white-space: nowrap; /* Keeps the text on one line */
	border-radius: 15px; /* Adds rounded corners for a softer, more button-like appearance */
}

.export-chat-history-button:hover {
	background-color: #5f51a0; /* Example hover background color (blue) */
	color: #ffffff; /* Example text color on hover (white) */
	box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Example shadow for depth effect */
	cursor: pointer; /* Change cursor to indicate it's clickable */
}










.analyze-modal-overlay,
.analyze-modal-content,
.modal-split,
.chat-analysis-container,
.side-panel,
.sidechat-container,
.input-container,
.input-container input,
.input-container button {
	box-sizing: border-box;
}



.new-button {

  border: gray;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  width: 36px;
  height: 36px;
  line-height: 24px;
  text-align: center;
  color: rgba(211, 148, 247, 0.913); /* You can adjust this color if needed */
  background:transparent;
}
	
.new-button:hover {
	background-color: color(display-p3 0.737 0.929 0.784);
}


/* For the AnalyzeChatHistoryModal overlay */
.analyze-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.905);
	width: 100vw;
	height: 100vh;
	z-index: 1000;
}

.analyze-modal-content{
	position: relative;
	background: color(display-p3 0.635 0.639 0.894);
	font-family: 'Avenir';
	width: 100vw;
	height: 100vh;
	display: flex;
}

.modal-split {
	
	display: flex;
	justify-content: flex-start; 
	height: 100%;
	width: 100%; 
}
body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}

.chat-analysis-container {
	font-family: 'Avenir';
	justify-content: space-between;
	flex: 1;
	overflow-y: scroll;
	background: whitesmoke;
	padding: 15px;
	justify-content: flex-end;
	display: flex;
	flex-direction: column;
}

.side-panel {
	flex: 0 0 20%; 
	background-color: #e6e6e6; /* Slightly darker grey for distinction */
	border-right: 1px solid #ccc; /* Light border */
	overflow-y: scroll;
	box-shadow: 2px 0px 10px rgba(0,0,0,0.1); /* Subtle shadow for depth */
	height: 100%;
	padding:15px;
}


.sidechat-container {
	height: 90%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}


.input-wrapper {
	position: relative;
	display: flex;
	align-items: center;
	background-color: #fff; /* match your input background */
	border: 1px solid #ccc;  /* mimic an input border */
	border-radius: 15px;  /* rounded corners */
	overflow: hidden;  /* ensure content doesn't spill out */
	justify-content: space-between;
	margin-top: 20px; /* Added this */
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);  /* Add shadow */
}

.input-wrapper div[contenteditable="true"] {
	padding: 10px 40px 10px 10px; /* Add padding to the right to make space for the send button */
	font-size: 16px;
	margin: 0;
	background-color: transparent;
	outline: none;
	flex-grow: 1;     /* Allow it to grow */
	flex-shrink: 1;   /* Let it shrink if required */
	border: none;     /* Ensure no border is taking up extra space */
	min-height: 40px;   /* Minimum height of the input field */
	max-height: 150px;  /* Maximum vertical extent to which the input can grow */
	overflow-y: auto;   /* Add scroll when content exceeds max-height */
}


[contentEditable=true]:empty:not(:focus):before {
	content: attr(data-placeholder);
	color: #aaa;  /* Placeholder text color */
}



.send-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	padding: 10px;
	transition: background-color 0.3s;
	flex: none;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 15%;  
	margin-right:5px; 
}

.send-button i {
	color: #c4c4c4;
}

/* When the user has typed something */
.send-button.active {
	background-color: #a2a3e9;
}

.send-button:hover {
	background-color: #b2e6c5;
}



.ongoing-conversation {
	overflow-y: scroll;
	background-color: transparent;	
	flex: 1;
	padding-bottom: 20px;
	padding-left: 15px;
	padding-right:15px;
	padding-top:15px;


}

.ongoing-conversation .message {
	padding-bottom: 20px; 
	padding-left: 20px;
	padding-right:20px;
	padding-top:20px;
	min-height: 30wh;
	text-align: justify;
	border-radius: 4px;
	border: 1px solid #c4c4c4; 
}

.ongoing-conversation .user{
	align-self: flex-start;
	background-color: #fff;
	color: #333;
}

.ongoing-conversation .assistant {
	align-self: flex-start;
	background-color: #edf4f1;
	color: #2e2f32;
}



.loading-indicator {
  border: 5px solid #f3f3f3; 
  border-top: 5px solid #b2e6c5; 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  align-self: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Styling for the pagination container */
.pagination {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  margin: 30px 100px;
  font-family: 'Avenir';
}

.pagination-buttons {
  display: flex;
  background-color: #f4f4f4;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styling for individual pagination buttons */
.pagination button {
  font-weight: 300;
  padding: 8px 16px; /* Increased padding for a larger click target */
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  color: #333;
  border: none; /* Removed border */
  background-color: #f4f4f4;
  border-right: 1px solid #ddd; /* Right border for separation */
}

.pagination button:last-child {
  border-right: none; /* Remove right border for the last button */
}

/* Styling for pagination buttons on hover */
.pagination button:hover {
  background-color: #b2e6c5); /* Changed to a softer green */
  color: #333;
}

/* Styling for the active pagination button */
.pagination button.active {
  background-color: #b2e6c5;
  color: #333;
  font-weight: 500;
}

/* Chats.css */

/* Style for the side panel list items */
.side-panel ul {
  list-style-type: none;
  padding: 0;
}

.side-panel li {
  padding: 10px;
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s;
  background: #e8e9ed;
}

.side-panel li:hover {
  background-color: #f0f0f0; /* Optional: Change background on hover for better UX */
}

/* Optional: Active state styling */
.side-panel li.active {
  background-color: #d0d0d0;
}

.syntax-highlighter {
  cursor: pointer;
}

.syntax-highlighter:hover {
  background-color: rgba(0,0,0,0.5); /* Adds a subtle highlight on hover */
}
.notification {
  position: fixed;
  padding: 10px;
  background-color: color(display-p3 0.737 0.929 0.784);
  border: 1px solid #444;
  border-radius: 4px;
  display: none;
}


 @keyframes typing {
	0% { opacity: 0; }
	60% { opacity: 1; }
	100% { opacity: 0; }
 }
 
.assistant.thinking span {
   animation-name: typing;
   animation-duration: 1.2s;
   animation-iteration-count: infinite;
 }
 
 .assistant.thinking span:nth-child(2) {
   animation-delay: 0.2s;
 }
 
 .assistant.thinking span:nth-child(3) {
   animation-delay: 0.4s;
 }
