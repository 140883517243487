@font-face {
	font-family: 'Avenir';
	src: url('../Avenir/Avenir-Black.ttf') format('truetype');
	font-weight: 900; /* Black */
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../Avenir/Avenir-Book.ttf') format('truetype');
	font-weight: 400; /* Book/Regular */
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../Avenir/Avenir-Heavy.ttf') format('truetype');
	font-weight: 700; /* Heavy/Bold */
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../Avenir/Avenir-Light.ttf') format('truetype');
	font-weight: 300; /* Light */
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../Avenir/Avenir-Regular.ttf') format('truetype');
	font-weight: 500; /* Regular/Medium */
	font-style: normal;
}

body {
	font-family: 'Avenir';
}



.dropdown-wrapper {
  position: relative;
}

.dropdown-icon {
  cursor: pointer;
  background-image: url('dropdown-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-top: 5px;
  transition: box-shadow 0.1s ease-in-out; /* Added transition for smooth effect */
}

.dropdown-icon:hover {
  box-shadow: 0 0 8px rgba(211, 148, 247, 0.913); /* Glow effect */
}

.dropdown-menu {
  display: none;
  position: absolute;
  right: 4px;
  top:calc(100% - 5px);
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1;
}

.dropdown-wrapper:hover .dropdown-menu {
  display: block; /* Shows the dropdown when hovering over the wrapper */
}



/* Distinct styles for the new WorkHub link */
.workhub-wrapper {
  display: flex;
  align-items: center;
  gap: 5px; /* Space between icon and link */
  border: 2px solid color(display-p3 0.635 0.639 0.894); /* Green border to make it stand out */
  padding: 5px;
  border-radius: 5px;
}

.workhub-icon {
  width: 20px;
  height: 20px;
  background-color: color(display-p3 0.737 0.929 0.784);
  background-image: url('workhub-icon.png');
  background-size: cover;
  background-position: center;
  border-radius: 50%; /* Make it circular */
  display: inline-block;
}

.nav-link-special a {
  color: color(display-p3 0.486 0.491 0.792); /* Green text to match the icon */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.nav-link-special a:hover {
  color: color(display-p3 0.902 0.776 0.973); /* Darken the green on hover */
}

.api-container {
    
	padding: 20px;
	border-color: #f7f8fc;
	border-radius: 15px;
	
}

.api-container h2 {
	font-weight: 700;
	font-size: 24px;
	color: #2e2f32;
	margin-bottom: 20px;
	background-color: #f7f8fc;
	padding: 10px 15px;
    position:relative;
}

.api-container div {
	margin-bottom: 20px;
}

.api-container input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  border: 1px solid #a2a3e9;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;  /* Add this line */
}




.api-container ul {
	list-style-type: none;
	padding: 0;
}

.api-container li {
	padding: 10px;
	background-color: #fff;
	border: 1px solid #a2a3e9;
	border-radius: 4px;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.api-container li button {
	padding: 5px 10px;
	font-size: 14px;
	background-color: #e94949;
	border: none;
	border-radius: 4px;
	color: #fff;
	cursor: pointer;
	transition: background-color 0.3s;
}

.api-container li button:hover {
	background-color: #cc3939;
}

.api-error {
	color: red;
	margin-bottom: 20px;
}

/* ... (rest of your styles) ... */

/* Modal Styles */
.modal {
  display: none; /* Default is hidden */
}



.modalShow {
  top: 0; 
  position: fixed;
  z-index: 1;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;  /* Change from block to flex */
  justify-content: center;
  align-items: center;
}



.modal-content {
  
  background-color: #fff;
  margin: 20px auto 0 auto; 
  padding: 20px;
  border-radius: 4px;
  width: 30%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

}


.modal h2 {
  font-size: 18px;
  font-weight: 700;
  color: #2e2f32;
  margin-bottom: 20px;
}


/* ... existing styles ... */
.input-group input {
  width: 100%;
  box-sizing: border-box;
}



.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 10px; 
  
}
.button-group button {
  margin-right: 10px;  /* Add this line */
}

.button-group button:last-child {
  margin-right: 0;  /* Add this line to remove margin from the last button */
}

.modal-content button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content button:first-child {
  background-color: #91c9a3;
  color: #fff;
}

.modal-content button:first-child:hover {
  background-color: #91c9a3;
 
}

.modal-content button:last-child {
  background-color: #dc6974;
  color: #fff;
}

.modal-content button:last-child:hover {
  background-color: #dc6974;
}

/* ... rest of your styles ... */


.api-keys-table button {
  background-color: #dc6974;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
}

.api-keys-table button:hover {
  background-color: #e87179;
}


.api-keys-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.api-keys-table th,
.api-keys-table td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
}

.api-keys-table th {
  background-color: #f2f2f2;
}


.api-key-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px; /* Add some space between the info and the delete button */
}


.api-key-info span {
  margin-right: 10px; /* Add some space between the API key and the expiration date */
}


.generate-api-key-button {
  padding: 10px 20px;
  font-size: 14px;
  background-color: #91c9a3;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto;  /* Ensure the button doesn't stretch across the screen */
}

.generate-api-key-button:hover {
  background-color: #a5daac;
}



.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-indicator-container {
  width: 50px;
  height: 50px;
}

.loading-indicator {
  border: 5px solid #f3f3f3; 
  border-top: 5px solid #b2e6c5; 
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
