@font-face {
    font-family: 'Avenir';
    src: url('../Avenir/Avenir-Black.ttf') format('truetype');
    font-weight: 900; /* Black */
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('../Avenir/Avenir-Book.ttf') format('truetype');
    font-weight: 400; /* Book/Regular */
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('../Avenir/Avenir-Heavy.ttf') format('truetype');
    font-weight: 700; /* Heavy/Bold */
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('../Avenir/Avenir-Light.ttf') format('truetype');
    font-weight: 300; /* Light */
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('../Avenir/Avenir-Regular.ttf') format('truetype');
    font-weight: 500; /* Regular/Medium */
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


.Registration {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('./wave.jpg');  /* adding the background image */
  background-size: cover;  /* ensure the image covers the full viewport */
  background-repeat: no-repeat;  /* prevent the image from repeating */
  background-position: center center; 
}

.container {
  display: flex;
  width: 50%;   /* Set the width of the container to 80% of its parent */
  height: 500px; /* You can adjust this to a value that suits your needs */
  margin: 0 auto; /* This centers the container horizontally in the viewport */
  padding: 20px;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow for better UI */
  border-radius: 10px; /* Optional: rounds the corners */
}

.leftBox {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid #e1e1e1;
  padding: 20px;
  background:  rgba(255, 255, 255, 1);
}


.rightBox {
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background:  rgba(255, 255, 255, 1);
}

.noktlogo {
  width: 200px;
  height: auto;
}

.noktHeader {
  font-family: 'Comfortaa', sans-serif; /* Use Comfortaa font, fallback to sans-serif */
}

.dashboardHeader {
  font-family: 'Comfortaa', sans-serif;
  text-align: left;
}

.subtitle {
  font-family: 'Roboto', sans-serif;
  text-align: left;
  }
  
.dashboardHeader {
  font-size: 24px;
  margin-bottom: 10px;
}

.subtitle {
  margin-bottom: 20px;
}


.forgot-password-button {
  background-color: #5f51a0;
}


/* ... existing styles ... */

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        width: 90%;
        height: auto;
    }

    .leftBox, .rightBox {
        width: 100%;
        padding: 5px ;
    }

    .leftBox {
        border-right: none;
        border-bottom: 1px solid #e1e1e1;
    }

    /* Adjustments for the elements inside the boxes */
    .noktlogo {
        width: 150px;
        height: auto;
    }

    .noktHeader, .dashboardHeader {
        font-size: 20px;
    }

    .subtitle {
        font-size: 14px;
        margin-bottom: 15px;
    }

    label, input, button {
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
    }

    input {
        padding: 8px;
        width: 100%;
    }

    button {
        padding: 8px 12px;
    }
}

/* Filters Styles */

.filters-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filter {
    display: inline-block;
    margin-right: 10px;
}

/* Alert List Styles */

.alert-list {
    list-style-type: none;
    padding: 0;
}

.alert-item {
    padding: 10px;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Avenir';
}

.alert-item:last-child {
    border-bottom: none;
}

.alert-content {
    flex: 1;
}

.alert-agent, .alert-time, .alert-criticality {
    margin-left: 10px;
}


@media (min-width: 769px) {
    .container {
        height: auto;  /* make the container's height adaptable */
        padding: 10px;
    }

    .noktHeader, .dashboardHeader {
        font-size: 24px;  /* reduce font size */
        margin-bottom: 8px;
    }

    .subtitle {
        font-size: 16px;  /* reduce font size */
        margin-bottom: 15px;
    }

    label {
        font-size: 14px;  /* reduce font size */
        margin-bottom: 4px;
    }

    input {
        font-size: 14px;  /* reduce font size */
        padding: 8px;
    }

    button {
        font-size: 14px;  /* reduce font size */
        padding: 8px 12px;
    }
    
    .noktlogo {
        width: 180px;
        height: auto;
    }
    

    .leftBox, .rightBox {
        padding: 10px;
    }
}

input:focus {
    border-color: #7b85fc ;
    outline: none;
    background-color: #e8e9ed;
}

h1 {
  font-size: 20px;
  margin: 0;
  color: #2e2f32;
}

p {
  margin-bottom: 20px;
  color: #2e2f32;
  text-align: middle;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;  
  color: #2e2f32;
  text-align: left;
  font-family: 'Roboto', sans-serif;
}

input {
  width: 94%;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;  
  padding-left: -50px;
  font-family: 'Roboto', sans-serif;
}

button {
  display: block;
  width: 100%;
  padding: 10px 15px;
  background-color: #8118ab; 
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  font-family: 'Roboto', sans-serif;
}

button:hover {
  background-color: #b9bdf6; 
}
